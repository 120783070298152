/*Default Css*/
.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

.App-header {
   background-color: black;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: #db545a;
}

a {
   color: #db545a;
}

/*photo*/
.u-image,
.u-background-effect-image,
.u-video-poster {
   object-fit: cover;
   display: block;
   background-size: cover;
   background-position: 50% 50%;
   background-repeat: no-repeat;
}

.u-image-circle,
.u-image {
   border-radius: 50% !important;
}

.u-section-1,
.u-image-1 {
   width: 386px;
   height: 386px;
   background-position: 20% 50%;
   margin: 31px auto 0;
}
/*paragraph*/
.u-align-justify {
   text-align: justify;
}

.u-text {
   word-wrap: break-word;
   position: relative;
}

.u-text-grey-40 {
   color: #999999;
}

.u-section-1,
.u-text-3 {
   font-weight: 300;
   line-height: 1.8;
   width: 739px;
   margin: 20px auto 0;
}
/*Title*/
.u-section-1,
.u-text-1 {
   font-size: 4.5rem;
   margin: 110px auto 0;
}

.u-font-merriweather,
.u-custom-font {
   font-family: Merriweather, serif !important;
}

.u-text-palette-2-base {
   color: #db545a !important;
}

.u-text-default {
   display: table;
   align-self: flex-start;
   width: 100%;
}
/*icons*/
.icons {
   width: 60px;
   padding-bottom: 10px;
}
/*View Portfolio*/
.portfolio-table {
   padding: 30px;
   margin-left: 20%;
   margin-right: 20%;
   border-top: 1px solid #db545a;
}

.portfolio-1 {
   font-size: 3rem;
   line-height: 1.6;
   width: 795px;
   font-weight: 700;
   margin: 51px auto 0px;
   text-align: center;
}

.portfolio-2 {
   text-transform: uppercase;
   letter-spacing: 2px;
   font-weight: 700;
   font-family: sans-serif;
   text-align: center;
}

/*Portfolio Table*/
.card {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   transition: 0.3s;
   width: 40%;
}

.card:hover {
   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
   padding: 2px 16px;
}

/*Container Table*/
.profile {
   border-style: solid;
   font-size: 1.25rem;
   text-transform: uppercase;
   letter-spacing: 2px;
   font-weight: 700;
   margin: 60px auto;
   padding: 20px 63px 20px 61px;
   border-width: 2px;
}

.profile:hover {
   background-color: white;
   color: black;
}

a:hover {
   color: black;
}

table {
   border-spacing: 50px;
}

.profile-table {
   margin-left: 50px;
   margin-right: 50px;
}

.profile-images {
   height: 200px;
   width: 100%;
   object-fit: cover;
   display: block;
   border-radius: 50px;
}

ul {
   list-style-type: none;
   margin: 0;
   padding: 0;
   overflow: hidden;
   background-color: white;
}

li {
   float: right;
}

li a {
   display: block;
   color: black;
   text-align: center;
   padding: 14px 16px;
   text-decoration: none;
}

li a:hover {
   background-color: gray;
}
